import AuthenticationService from '~/services/AuthenticationService'

export default defineNuxtRouteMiddleware((to) => {
  if (process.client) {
    // executa apenas para as rotas do dashboard
    if (
      to.path !== '/entrar' &&
      to.path !== '/registrar' &&
      to.path !== '/registrar-donoespaco' &&
      !AuthenticationService.isAuthenticated()
    ) {
      window.location.href = '/entrar'
    }
    // executa para as rotas /entrar e /registrar
    if (
      (to.path === '/entrar' ||
        to.path === '/registrar' ||
        to.path === '/registrar-donoespaco') &&
      AuthenticationService.isAuthenticated()
    ) {
      window.location.href = '/minha-conta'
    }
  }
})
